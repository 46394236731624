import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
class loginHeader extends React.Component
{
  render()
  {
    return (
        <header className="header-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>SOURCI<br/><span>AI BOT</span></h1>
                        </div>
                    </div>
                </div>      
        </header>
       
    )
  }
}
export default loginHeader;