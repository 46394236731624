import React, { Component } from 'react'
import Header from "../elements/header";
import Footer from '../elements/footer';
import API from '../axios/axiosApi';

export default class product extends Component {
    constructor(props) {
        super(props);
        this.state={
			modalIsOpen: false,
            value:this.props.location.state,
			limit : 12,
			seeMoreIsCliked: false,
			itemSearched : "",
			newProperties : [],
			setLoader: false,
			errMsg: ""
        }
    }

	 componentWillMount() {
        API.get('user',{ headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
          }})
          .then(response => {
              var userRole = response.data.sub_role;
              var userName = response.data.user_name;
              var emailId = response.data.email_id
              localStorage.setItem('email', emailId);
              this.setState({ userRole, userName, isSpinning: true });
              
          }) 
          .catch(error => {
              console.log(error);
          });
      }

	  modelOpen = (value) => {
		  this.setState({value,modalIsOpen: true})
	  }

	  seeMore = (e) =>
	  {
		e.preventDefault()
		this.setState({seeMoreIsCliked: true})
		console.log("firstLoad")
		var recLength = this.props.location.state.length;
		console.log(recLength)
		const currentLimit = this.state.limit;
		console.log(currentLimit)

		if (currentLimit < recLength) {
			this.setState({ limit: recLength });
		 }
		 window.scroll({
			top: document.body.offsetHeight,
			left: 0, 
			behavior: 'smooth',
		  });
	  }

	  seeMoreNewSearch = (e) =>
	  {
		e.preventDefault()
		this.setState({seeMoreIsCliked: true})
		console.log("NewSearch")
		const newRecLength = this.state.newProperties.length;
		const currentLimit = this.state.limit;
		console.log(currentLimit)

		if (currentLimit < newRecLength) {
			this.setState({ limit: newRecLength });
		 }
		 window.scroll({
			top: document.body.offsetHeight,
			left: 0, 
			behavior: 'smooth',
		  });
	  }

	  seeMoreOnReload = (e) =>
	  {
		e.preventDefault()
		this.setState({seeMoreIsCliked: true})
		console.log("onreload")
		var list = JSON.parse(localStorage.getItem('listView'));
		var lsRecLength = list.length
		console.log(lsRecLength)
		const currentLimit = this.state.limit;
		console.log(currentLimit)

		if (currentLimit < lsRecLength) {
			this.setState({ limit: lsRecLength });
		 }
		 window.scroll({
			top: document.body.offsetHeight,
			left: 0, 
			behavior: 'smooth',
		  });
	  }

	handleNewSearchProduct = event => {
        this.setState({itemSearched: event.target.value, errMsg:"" });
    };
	
	handleNewSearch = (e) =>{
		e.preventDefault()
		this.setState({ setLoader: true, seeMoreIsCliked:false, limit:12, errMsg: "" });
		// this.setState({errMsg: ""})
		sessionStorage.setItem('new_search_term',this.state.itemSearched);
		API.post('scrape', {
            product : this.state.itemSearched,
          },{ headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'email': localStorage.getItem('email')
            }}).then((response) =>{
              var newProperties = response.data;
              console.log(newProperties)
              if(response.data.message){
                  this.setState({errMsg: response.data.message, setLoader: false})
              }else{
				this.setState({
					newProperties,setLoader: false,
				  },() => {
					localStorage.setItem('listView', JSON.stringify(this.state.newProperties))
				  });
              }
          }).catch(error =>{
              console.log(error);
          })
	}


 render(){
	var searchTerm = sessionStorage.getItem("search_term")
	var newSearchTerm = sessionStorage.getItem("new_search_term")
	var list = JSON.parse(localStorage.getItem('listView'));
	let layout = ""
	let layout2 = ""
	let layout3 = ""
	if(this.state.seeMoreIsCliked === false)
	{
		layout = (<p>Top 12 results shown. <a className="all-link" href="#/" onClick={this.seeMore}>See All</a></p>)
	}else{
		layout = (<h6>All results are shown below</h6>)
	}
	
	if(this.state.seeMoreIsCliked === false)
	{
		layout2 = (<p>Top 12 results shown. <a className="all-link" href="#/" onClick={this.seeMoreNewSearch}>See All</a></p>)
	}else{
		layout2 = (<h6>All results are shown below</h6>)
	}

	if(this.state.seeMoreIsCliked === false)
	{
		layout3 = (<p>Top 12 results shown. <a className="all-link" href="#/" onClick={this.seeMoreOnReload}>See All</a></p>)
	}else{
		layout3 = (<h6>All results are shown below</h6>)
	}
		 
	
 return (
     <div>
         <Header loading={this.state.isSpinning} userName={this.state.userName}/>
        <header className="sub-header-search">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 search-bar">
                    <form className="form-inline my-2 my-lg-0">
                    	<input className="form-control mr-sm-2" type="search" placeholder="New Search?"  aria-label="Search" onChange={this.handleNewSearchProduct}/>
                    	<button className="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={this.handleNewSearch}>LET'S DO THIS</button>
                    </form>
					{this.state.newProperties?.length !== 0 ? null: <p className="errMsg">{this.state.errMsg}</p>}
                    </div>
					
                </div>
            </div>
	    </header>
		
		{ this.state.newProperties?.length !== 0 ? (
			<section className="product-area">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="heading-content">
							<h1>Search Results: {this.state.setLoader === true? <p className="searchMsg">Search started for new product</p> : newSearchTerm.charAt(0).toUpperCase()+ newSearchTerm.slice(1)}</h1>
							{this.state.setLoader === true ? null : layout2}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="profile-product">
					{this.state.setLoader === true ?
						<div class="loader-bg">
						<div class="loader">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						</div>
						<div class="loder-text">
						<p>Sit tight, this takes about <span class="punk-text">20 minutes.</span> <br/>Go and grab a <span class="sky-blue-text">coffee.</span> </p>
						</div>
					</div>:
					this.state.newProperties.slice(0,this.state.limit).map((value,index) => {
					return (
					<div className='col-md-3' key={index}>
						<div className="profile-image-area">
							<img src={value.main_img_url} alt="profile"/>
						</div>
						<div className="profile-details-area">
								<h4>{value.factory_name}</h4>
								<div className="star-rating">
								{(() => {
								if(value.supplier_rating_score !== "NA"){
									const stars = [];
									var ratingCount = Math.round(value.supplier_rating_score)
									for (let i = 1; i<=5 ; i++) {
										if (ratingCount < i) {
											stars.push(<label htmlFor="5-stars" className="star" key={i}>☆</label>);
										  } else {
											stars.push(<label htmlFor="5-stars" className="star" key={i}>★</label>);
										  }
										}
										return stars;
								}else{
									const stars = [];
									for (let i = 1; i<=5 ; i++) {
										stars.push(<label htmlFor="5-stars" className="star" key={i}>☆</label>);
										}
									return stars;
								}
								  })()}
								  </div>
								<div className="product-addess-details">
									<ul className="address-details">
										<li className="area-distance">
											<img src="/building.png" alt="building"/>
											<p>{value.factory_size}</p>
										</li>
										<li className="location-details">
											<img src="/location.png"  alt="location"/>
											<p>{value.location}</p>
										</li>
										<li className="men-power">
											<img src="/people.png" alt="men-power"/>
											<p>{value.total_employee}</p>
										</li>
									</ul>
								</div>
								<div className="view-more-link">
									<a href="#/" data-toggle="modal" data-target="#exampleModal" onClick={()=> this.modelOpen(value)} val="{}"> View More </a>
								</div>
							</div>
					</div>
					)
					  })
					}
					</div>
					
				</div>
			</div>
		</section>
		): list != null && list !== '' ? 
		(
		<section className="product-area">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="heading-content">
						<h1>Search Results: {this.state.setLoader === true? <p className="searchMsg">Search started for new product</p> : newSearchTerm.charAt(0).toUpperCase()+ newSearchTerm.slice(1)}</h1>
						{this.state.setLoader === true ? null :layout3}
					</div>
				</div>
			</div>
			<div className="row">
				<div className="profile-product">
				{ this.state.setLoader ?<div className="loader-bg">
					<div className="loader">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					</div>
					<div className="loder-text">
					<p>Sit tight, this takes about <span className="punk-text">20 minutes.</span> <br/>Go and grab a <span className="sky-blue-text">coffee.</span> </p>
					</div>
				</div>:
				list.slice(0,this.state.limit).map((values,index) => {
				return (
				<div className='col-md-3' key={index}>
					<div className="profile-image-area">
						<img src={values.main_img_url} alt="profile"/>
					</div>
					<div className="profile-details-area">
							<h4>{values.factory_name}</h4>
							<div className="star-rating">
							{(() => {
							if(values.supplier_rating_score !== "NA"){
								const stars = [];
								var ratingCount = Math.round(values.supplier_rating_score)
								for (let i = 1; i<=5 ; i++) {
									if (ratingCount < i) {
										stars.push(<label htmlFor="5-stars" className="star" key={i}>☆</label>);
									  } else {
										stars.push(<label htmlFor="5-stars" className="star" key={i}>★</label>);
									  }
									}
									return stars;
							}else{
								const stars = [];
								for (let i = 1; i<=5 ; i++) {
									stars.push(<label htmlFor="5-stars" className="star" key={i}>☆</label>);
									}
								return stars;
							}
          					})()}
							  </div>
							<div className="product-addess-details">
								<ul className="address-details">
									<li className="area-distance">
										<img src="/building.png" alt="building"/>
										<p>{values.factory_size}</p>
									</li>
									<li className="location-details">
										<img src="/location.png" alt="location"/>
										<p>{values.location}</p>
									</li>
									<li className="men-power">
										<img src="/people.png" alt="men-power"/>
										<p>{values.total_employee}</p>
									</li>
								</ul>
							</div>
							<div className="view-more-link">
								<a href="#/" data-toggle="modal" data-target="#exampleModal" onClick={()=> this.modelOpen(values)} val="{}"> View More </a>
							</div>
						</div>
				</div>
				)
  				})
				}
				</div>
				
			</div>
		</div>
	</section>):
		( 
        <section className="product-area">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="heading-content">
						<h1>Search Results: {this.state.setLoader === true? <p className="searchMsg">Search started for new product</p> : searchTerm.charAt(0).toUpperCase()+ searchTerm.slice(1)}</h1>
						{this.state.setLoader === true ? null :layout}	
					</div>
				</div>
			</div>
			<div className="row">
				<div className="profile-product">
				{this.state.setLoader === true ? <div class="loader-bg">
				<div class="loader">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				</div>
				<div class="loder-text">
				<p>Sit tight, this takes about <span class="punk-text">20 minutes.</span> <br/>Go and grab a <span class="sky-blue-text">coffee.</span> </p>
				</div>
			</div>:
				this.props.location.state.slice(0,this.state.limit).map((value,index) => {
				return (
				<div className='col-md-3' key={index}>
					<div className="profile-image-area">
						<img src={value.main_img_url} alt="profile" />
					</div>
					<div className="profile-details-area">
							<h4>{value.factory_name}</h4>
							<div className="star-rating">
							{(() => {
							if(value.supplier_rating_score !== "NA"){
								const stars = [];
								var ratingCount = Math.round(value.supplier_rating_score)
								for (let i = 1; i<=5 ; i++) {
									if (ratingCount < i) {
										stars.push(<label htmlFor="5-stars" className="star" key={i}>☆</label>);
									  } else {
										stars.push(<label htmlFor="5-stars" className="star" key={i}>★</label>);
									  }
									}
									return stars;
							}else{
								const stars = [];
								for (let i = 1; i<=5 ; i++) {
									stars.push(<label htmlFor="5-stars" className="star" key={i}>☆</label>);
									}
								return stars;
							}
							
            				
          					})()}
							  </div>
							<div className="product-addess-details">
								<ul className="address-details">
									<li className="area-distance">
										<img src="/building.png" alt="building"/>
										<p>{value.factory_size}</p>
									</li>
									<li className="location-details">
										<img src="/location.png" alt="location"/>
										<p>{value.location}</p>
									</li>
									<li className="men-power">
										<img src="/people.png" alt="men-power"/>
										<p>{value.total_employee}</p>
									</li>
								</ul>
							</div>
							<div className="view-more-link">
								<a href="#/" data-toggle="modal" data-target="#exampleModal" onClick={()=> this.modelOpen(value)} val="{}"> View More </a>
							</div>
						</div>
				</div>
				)
  				})
				}
				</div>
				
			</div>
		</div>
	</section>
		)
		}
    <Footer/>
	<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div className="modal-dialog" role="document">
		    <div className="modal-content">
		      <div className="modal-header">
		        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div className="modal-body">
		        <div className="container">
				{
                    (() => {
                        if(this.state.modalIsOpen === true) {
                                return (
                                    <div>
                                    <div className="row">
										<div className="col-md-12">
											<div className="profile-modal-area">
												<div className="profile-image-area">

													<img src={this.state.value.main_img_url} alt="profile"/>
												</div>
												<div className="profile-details-area">
													<h4>{this.state.value.factory_name}</h4>
													<div className="star-rating">
													{(() => {
														if(this.state.value.supplier_rating_score !== "NA"){
															const stars = [];
															
															var ratingCount = Math.round(this.state.value.supplier_rating_score)
															for (let i = 1; i<=5 ; i++) {
																if (ratingCount < i) {
																	stars.push(<label htmlFor="5-stars" className="star" key={i}>☆</label>);
																} else {
																	stars.push(<label htmlFor="5-stars" className="star" key={i}>★</label>);
																}
																}
																return stars;
														}else{
															const stars = [];
															for (let i = 1; i<=5 ; i++) {
																stars.push(<label htmlFor="5-stars" className="star" key={i}>☆</label>);
																}
															return stars;
														}
          											})()}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="row table-view-format">
										<div className="col-md-4">
											<table>
												<tbody>
												<tr>
												<td>Product Name:</td>
												<td>{this.state.value.product_name}</td>
												</tr>
												<tr>
												<td>Price:</td>
												<td>{this.state.value.price_value}</td>
												</tr>
												<tr>
												<td>Trade Assurance:</td>
												<td>{this.state.value.trade_assurance}</td>
												</tr>
												<tr>
												<td>Business Type:</td>
												<td>{this.state.value.business_type}</td>
												</tr>
												<tr>
												<td>Trademark:</td>
												<td>{this.state.value.trademark}</td>
												</tr>
												<tr>
												<td>Year Established:</td>
												<td>{this.state.value.year_established}</td>
												</tr>
												<tr>
												<td>Factory Size:</td>
												<td>{this.state.value.factory_size}</td>
												</tr>
												<tr>
												<td>Total Annual Revenue:</td>
												<td>{this.state.value.total_annual_revenue}</td>
												</tr>
												</tbody>
											</table>
										</div>
										<div className="col-md-4">
											<table>
												<tbody>
												<tr>
												<td>Factory Location:</td>
												<td>{this.state.value.factory_location}</td>
												</tr>
												<tr>
												<td>Production Lines:</td>
												<td>{this.state.value.production_lines}</td>
												</tr>
												<tr>
												<td>Annual Output Value:</td>
												<td>{this.state.value.annual_out_value}</td>
												</tr>
												<tr>
												<td>Company Profile Link:</td>
												<td>{this.state.value.company_profile_link}</td>
												</tr>
												<tr>
												<td>Supplier Rating Score:</td>
												<td>{this.state.value.supplier_rating_score}</td>
												</tr>
												<tr>
												<td>Review:</td>
												<td>{this.state.value.reviews}</td>
												</tr>
												<tr>
												<td>Certifications:</td>
												<td>{this.state.value.certifications}</td>
												</tr>
												</tbody>
											</table>
										</div>
										<div className="col-md-4">
											<table>
												<tbody>
												<tr>
												<td>Response Time:</td>
												<td>{this.state.value.buyer_intraction_res_time}</td>
												</tr>
												<tr>
												<td>Response Rate:</td>
												<td>{this.state.value.buyer_intraction_res_rate}</td>
												</tr>
												<tr>
												<td>Quotation Performance:</td>
												<td>{this.state.value.quotation_performance}</td>
												</tr>
												<tr>
												<td>Supplier Index Count:</td>
												<td>{this.state.value.supplier_index_count}</td>
												</tr>
												<tr>
												<td>Image Urls:</td>
												<td className="image-urls-link" >{this.state.value.image_urls}</td>
												</tr>
												<tr>
												<td>--------</td>
												<td>---------</td>
												</tr>
												<tr>
												<td>---------</td>
												<td>---------</td>
												</tr>
												<tr>
												<td>---------</td>
												<td>---------</td>
												</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div className="row modal-fotter-address">
										<div className="product-addess-details">
												<ul className="address-details">
													<li className="area-distance">
														<img src="/building.png" alt="building" />
														<p>{this.state.value.factory_size}</p>
													</li>
													<li className="location-details">
														<img src="/location.png" alt="location"/>
														<p>{this.state.value.location}</p>
													</li>
													<li className="men-power">
														<img src="/people.png" alt="men-power"/>
														<p>{this.state.value.total_employee}</p>
													</li>
												</ul>
											</div>
									</div>
                                </div>
                                )
								}
                   			 })()  
                    		}  
		        	</div>
		      	</div>
		    </div>
		  </div>
		</div>
     </div>
 
     )
 }
 
 }

