import React, { Component } from 'react'
import Header from "../elements/header";
import Footer from '../elements/footer';
import {Redirect} from 'react-router-dom';
import API from '../axios/axiosApi';

export default class search extends Component {
    constructor(props){
        super(props)
       this.state = { 
        productItem: '',
        properties : [],
        toProduct: false,
        setLoader: false,
        userName : "",
        userRole: '',
        isSpinning: false,
        errMsg: ""

        };
    }

    componentWillMount() {
        API.get('user',{ headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
          }})
          .then(response => {
              var userRole = response.data.sub_role;
              var userName = response.data.user_name;
              var emailId = response.data.email_id
              localStorage.setItem('email', emailId);
              this.setState({ userRole, userName, isSpinning: true });
              
          }) 
          .catch(error => {
              console.log(error);
          });
      }

    handleSearchProduct = event => {
        this.setState({productItem: event.target.value, errMsg:"" });
    };

    handleSearch = (e) => {
        e.preventDefault();
        console.log("okay");
        this.setState({ setLoader: true });
        this.setState({errMsg: ""})
        sessionStorage.setItem('search_term',this.state.productItem);
        if(localStorage.getItem('listView'))
		{
			localStorage.removeItem('listView');
		}
        // this.setState({productItem: "abc"})
        API.post('scrape', {
            product : this.state.productItem,
          },{ headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'email': localStorage.getItem('email')
            }}).then((response) =>{
              var properties = response.data;
              console.log(properties)
              if(response.data.message){
                  this.setState({errMsg: response.data.message, setLoader: false})
              }else{
                this.setState({properties, setLoader: false, toProduct: true})
              }
          }).catch(error =>{
              console.log(error);
          }) 
    }

    handleSearchKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
        this.setState({productItem: e.target.value, errMsg:"" });
        this.handleSearch();
        }
    }
   


  render() {
    return (
        <div>
        <Header loading={this.state.isSpinning} userName={this.state.userName}/>
        
        <section className="search-content-area">
        {
                    (() => {
                        if(this.state.setLoader === true) {
                                return (
                                    // <div><img alt="loader" src= "/ajax-loader.gif" /></div>
                                    <div>
                                    <div class="loader-bg">
                                        <div class="loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        </div>
                                        <div class="loder-text">
				                        <p>Sit tight, this takes about <span class="punk-text">20 minutes.</span> <br/>Go and grab a <span class="sky-blue-text">coffee.</span> </p>
			                            </div>
                                    </div>
                                    </div>
                                )
                        
                            } else if (this.state.toProduct === true) {
                                return (
                                    <Redirect to={{
                                        pathname: '/product',
                                        // state: {productItem: "abc", productDetail:this.state.properties},
                                        state: this.state.properties,
                                        //searchTerm: this.state.productItem
                                    }} />
                                )
                            }
                    })()  
                    }  
            <div className="container">
                <div className="row">
                    <div className="col-md-12 search-bar-area">
                        <h1>ARE YOU READY TO MAKE SOMETHING <span className="special-character">AMAZING?</span></h1>
                        <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-sm-2" type="search" placeholder="What are you looking for, today?" aria-label="Search" onChange={this.handleSearchProduct} onKeyPress={this.handleKeypress}/>
                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={this.handleSearch}>Search</button>
                    </form>
                    {this.state.properties ? <p className="errMsg">{this.state.errMsg}</p>: null}
                    </div>
                </div>
            </div>
	    </section>
        <Footer/>
        </div>
    )
  }
}
