import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from "./pages/login";
// import Dashboard from "./pages/dashboard";
import Product from "./pages/product";
import Search from "./pages/search";
import './App.css';





class App extends Component {

    render() {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route exact path='/' component={Login} />
                        {/* <Route path='/dashboard' component={Dashboard} /> */}
                        <Route path='/search' component={Search} />
                        <Route path='/product' component={Product} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
