import axios from 'axios';

const API = axios.create({
	//baseURL: "http://localhost:3000/",
	baseURL: 'http://alibaba-api.sourciglobal.com/',
	//baseURL : 'http://alibaba.sourciglobal.com:3000/',
	headers: {'Content-Type':  'application/json'}
});

export default API;
