import React, {Component} from 'react';
import API from '../axios/axiosApi';
import {Redirect} from 'react-router-dom';
// import TitleComponent from "./title";
import Footer from '../elements/footer';
import Head from "../elements/loginHeader";

export default class Login extends Component {

    state = {
        email: '',
        password: '',
        redirect: false,
        authError: false,
        isLoading: false,
        location: {},
        loginStatus:'',
    };

    handleEmailChange = event => {
        this.setState({email: event.target.value});
    };
    handlePwdChange = event => {
        this.setState({password: event.target.value});
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        const email = this.state.email;
        console.log(email);
        const password = this.state.password;
        const data = {
            email: email,
            password : password 
        };
       
        API.post('login', data, { headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }})
            .then(result => {
                if (result.data.message) {
                    this.setState({ loginStatus: result.data.message , isLoading: false});
                }
                else{
                    localStorage.setItem('token', result.data.token);
                    this.setState({isLoading: false});
                    localStorage.setItem('isLoggedIn', true);
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({authError: true, isLoading: false});
            });
    };


      renderRedirect = () => {
        if (localStorage.getItem('token') != null) {
            // return <Redirect to='/dashboard'/>
            return <Redirect to='/search'/>
        }
    };

    

    render() {
        const isLoading = this.state.isLoading;
        return (
            <div>
                <Head />
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li><a href="#/">Having Issues? </a></li>
                                    <li><a className="web-link" href="#/">Got Help </a></li>
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <div className="global-container">
                                    <div className="card login-form">
                                        <div className="card-body">
                                            <h3 className="card-title">Sign in to Sourci</h3>
                                            <p className="sub-heading"> Ready to start your journey today?</p>
                                            <div className="card-text">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Email address</label>
                                                        {/* <input type="email" class="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp"/> */}
                                                        <input className={"form-control form-control-sm " + (this.state.authError ? 'is-invalid' : '')} id="inputEmail" placeholder="Email address" type="text" name="email" onChange={this.handleEmailChange} autoFocus required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Password</label>
                                                        <a href="#/">Forgot password?</a>
                                                        {/* <input type="password" class="form-control form-control-sm" id="exampleInputPassword1"/> */}
                                                        <input type="password" className={"form-control form-control-sm" + (this.state.authError ? 'is-invalid' : '')} id="inputPassword" placeholder="******" name="password" onChange={this.handlePwdChange} required/>
                                                    </div>
                                                    {/* <button type="submit" class="btn btn-primary btn-block">Sign In</button> */}
                                                    <button className="btn btn-primary btn-block" type="submit" disabled={this.state.isLoading ? true : false}>Login &nbsp;&nbsp;&nbsp;
                                                        {isLoading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </button>
                                                    <div className="sign-up">
                                                        Don't have an account? <a href="#/">&nbsp; Sign Up</a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
	            </section>
                <Footer/>
            </div>
        );
    }
}


